<template>
  <div class="home">
    <v-row>
    <h1 class="text-sm-h3 text-h4 my-3 mx-auto">Home</h1>
    </v-row>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {}
}
</script>
