<template>
  <v-app>
    <v-app-bar
      app
      dense
      flat
      dark
      color="blue darken-3"
      class="text-center"
      clipped-left
    >
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        class="d-md-none"
      >
      </v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-app-bar-title dark>ZSH Orders</v-app-bar-title>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
      flat
      color="blue darken-3"
      dark
      :permanent="$vuetify.breakpoint.mdAndUp"
    >
      <v-list nav dense>
          <v-list-item
            v-for="item in items" :key="item.target"
            :to="{ name: item.target }"
            exact
          >
            <v-list-item-title>{{item.title}}</v-list-item-title>
          </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>

  </v-app>
</template>

<script>

export default {
  name: 'App',

  components: {},

  data: () => ({
    drawer: false,
    items: [
      {
        title: "Home",
        target: "Home"
      },
      {
        title: "Orders",
        target: "Orders"
      },
      {
        title: "Add Order",
        target: 'AddOrder'
      },
      {
        title: "About",
        target: "About"
      }

    ]
  }),
};
</script>
